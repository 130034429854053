export enum EnumUserRole {
    Administrator = 'ADMIN',
    Student = 'STUDENT',
    Support = 'SUPPORT'
}

export enum EnumUserGender {
    Male = 'MALE',
    Female = 'FEMALE',
    Uninformed = 'UNINFORMED',
    Other = 'OTHER'
}

export enum EnumUserSubscription {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Delayed = 'DELAYED',
    Canceled = 'CANCELED',
    CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
    CancelledBySeller = 'CANCELLED_BY_SELLER',
    CancelledByAdmin = 'CANCELLED_BY_ADMIN',
    Started = 'STARTED',
    Overdue = 'OVERDUE',
    Expired = 'EXPIRED'
}